import React, { useState } from 'react';

function FaqHomeOne({ className }) {
    const [showQues, setQues] = useState(1);
    const openQuestion = (value) => {
        setQues(value);
    };
    return (
        <>
            <section className={`appie-faq-area pt-50 pb-95 ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">F.A.Q</h3>
                                <p>Questions fréquentes.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div
                                className="faq-accordion wow fadeInRight mt-30"
                                data-wow-duration="1500ms"
                            >
                                <div
                                    className="accrodion-grp animated fadeIn faq-accrodion wow"
                                    data-wow-duration="1500ms"
                                    data-grp-name="faq-accrodion"
                                >
                                    <div
                                        onClick={() => openQuestion(1)}
                                        className={`accrodion ${showQues === 1 ? 'active' : ''}`}
                                    >
                                        <div className="accrodion-inner">
                                            <div className="accrodion-title">
                                                <h4>
                                                    Je suis à Franceville, puis-je en province
                                                    m’inscrire ?
                                                </h4>
                                            </div>
                                            <div
                                                className="accrodion-content"
                                                style={{
                                                    display: showQues === 1 ? 'block' : 'none',
                                                }}
                                            >
                                                <div className="inner">
                                                    <p>
                                                        N’importe qui dans n’importe quelle province
                                                        ou ville du GABON peut s’inscrire sur
                                                        Gabondeal et commencer par vendre / publier
                                                        ses biens et services.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        onClick={() => openQuestion(2)}
                                        className={`accrodion ${showQues === 2 ? 'active' : ''}`}
                                    >
                                        <div className="accrodion-inner">
                                            <div className="accrodion-title">
                                                <h4>Comment contacter le service client ?</h4>
                                            </div>
                                            <div
                                                className="accrodion-content"
                                                style={{
                                                    display: showQues === 2 ? 'block' : 'none',
                                                }}
                                            >
                                                <div className="inner">
                                                    <p>
                                                        Sur la page de connexion, cliquer sur le
                                                        menu « Contacter Nous »
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div
                                className="faq-accordion wow fadeInRight mt-30"
                                data-wow-duration="1500ms"
                            >
                                <div
                                    className="accrodion-grp animated fadeIn faq-accrodion wow"
                                    data-wow-duration="1500ms"
                                    data-grp-name="faq-accrodion"
                                >
                                    <div
                                        onClick={() => openQuestion(3)}
                                        className={`accrodion ${showQues === 3 ? 'active' : ''}`}
                                    >
                                        <div className="accrodion-inner">
                                            <div className="accrodion-title">
                                                <h4>Comment vendre un article sur Gabondeal ?</h4>
                                            </div>
                                            <div
                                                className="accrodion-content"
                                                style={{
                                                    display: showQues === 3 ? 'block' : 'none',
                                                }}
                                            >
                                                <div className="inner">
                                                    <p>
                                                        Publiez vos annonces sur Gabondeal sans
                                                        effort en suivant les étapes ci-dessous :
                                                        <br />
                                                        1. Connectez-vous à votre profil sur
                                                        Gabondeal ;
                                                        <br />
                                                        2. Cliquez sur le bouton « Vendre »;
                                                        <br />
                                                        3. Remplissez toutes les informations.
                                                        Choisissez une catégorie appropriée,
                                                        téléchargez les photos de votre article et
                                                        écrivez un titre clair avec une description
                                                        détaillée de ce que vous vendez. Après cela,
                                                        entrez un prix équitable et revérifiez les
                                                        informations que vous avez entrées ;
                                                        <br />
                                                        4. Si tout semble correct, cliquez sur le
                                                        bouton "Publier l'annonce" ;
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        onClick={() => openQuestion(4)}
                                        className={`accrodion ${showQues === 4 ? 'active' : ''}`}
                                    >
                                        <div className="accrodion-inner">
                                            <div className="accrodion-title">
                                                <h4>Avez-vous des boutiques physiques?</h4>
                                            </div>
                                            <div
                                                className="accrodion-content"
                                                style={{
                                                    display: showQues === 4 ? 'block' : 'none',
                                                }}
                                            >
                                                <div className="inner">
                                                    <p>
                                                        Nous ne sommes pas un boutique, mais plutôt
                                                        un marché en ligne. Chez Gabondeal, vous
                                                        pouvez acheter des biens à des personnes
                                                        réelles, vendre vos articles, trouver des
                                                        offres d'emploi, ou des services dont vous
                                                        avez besoin. Nous mettons à votre
                                                        disposition une plateforme pour vous aider à
                                                        trouver ce que vous cherchez, mais les
                                                        transactions sont réalisées directement avec
                                                        les personnes concernées.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default FaqHomeOne;
