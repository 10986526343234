import React from 'react';

function BlogHomeOne({ className }) {
    return (
        <>
            <section className={`appie-blog-area pt-100 pb-100 ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">
                                    <span className="deal-blue">Gabon</span>
                                    <span className="deal-green">deal</span>,<br />
                                    La 1<sup>ère</sup> porte vers de bonnes affaires au Gabon
                                </h3>
                            </div>
                        </div>
                        <div className="col-lg-12 appie-hero-content text-center">
                            <ul>
                                <li>
                                    <a href="#">
                                        <i className="fab fa-apple" /> Download for iOS
                                    </a>
                                </li>
                                <li className="for-gradient-border">
                                    <div>
                                        <a className="item-2" href="#">
                                            <i className="fab fa-google-play" /> Download for
                                            Android
                                        </a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default BlogHomeOne;
