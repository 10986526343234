import React from 'react';
import IconOne from '../../assets/images/icon/seachicon.png';
import IconTwo from '../../assets/images/icon/filtericon.png';
import IconThree from '../../assets/images/icon/chaticon.png';
import IconFour from '../../assets/images/icon/producticon.png';

function ServicesHomeOne({ className }) {
    return (
        <section className={`appie-service-area pt-90 pb-100 ${className}`} id="service">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="appie-section-title text-center">
                            <h3 className="appie-title">Une expérience conviviale</h3>
                            <p>Naviguez sans effort avec notre application.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon">
                                <img src={IconOne} alt="" />
                            </div>
                            <h4 className="appie-title">Recherche rapide</h4>
                            <p>
                                Trouvez ce que vous cherchez en un rien de temps.Notre algorithme de
                                recherche avancé analyse les mots-clés les plus pertinents pour vous
                                proposer les résultats les plus précis possibles.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-2 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon">
                                <img src={IconTwo} alt="" />
                            </div>
                            <h4 className="appie-title">Filtre par catégorie</h4>
                            <p>
                                Trouvez rapidement ce que vous cherchez en filtrant lesrésultats par
                                catégorie. C'est facile, rapide et efficace.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-3 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="icon">
                                <img src={IconThree} alt="" />
                            </div>
                            <h4 className="appie-title">Discussion Instantanée</h4>
                            <p>
                                Échangez rapidement, facilement et en temps réel avec le vendeur ou
                                l’acheteur avec notre fonctionnalité de "DiscussionInstantanée".
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-4 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <div className="icon">
                                <img src={IconFour} alt="" />
                            </div>
                            <h4 className="appie-title">Une multitude d’articles</h4>
                            <p>
                                Notre application offre une large sélection d'articles dans toutes
                                les catégories. Trouvez rapidement ce dont vous avez besoin et
                                profitez d'une expérience de recherche en ligne simplifiée.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServicesHomeOne;
