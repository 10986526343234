import React from 'react';
import { Link } from 'react-router-dom';

function Navigation() {
    return (
        <>
            <ul>
                <li>
                    <Link to="/">Accueil</Link>
                </li>
                <li>
                    <Link to="/">Fonctionnalités</Link>
                </li>
                <li>
                    <Link to="/">Notre équipe</Link>
                </li>
                <li>
                    <Link to="/">Nous contacter</Link>
                </li>
                <li>
                    <Link to="/">Faq</Link>
                </li>
            </ul>
        </>
    );
}

export default Navigation;
